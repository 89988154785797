import React from "react";

import Background from "../components/Background";
import Layout from "../components/Layout";

function Programmation() {
  return (
    <Background>
      <Layout title="PROGRAMMATION">
        <div>
          <p>Programmation</p>
        </div>
      </Layout>
    </Background>
  );
}

export default Programmation;
